import Header from '../components/header.component'
import Footer from '../components/footer.component';

const Contact = () => (
    <main role="main" className="inner cover text-center d-flex h-100 p-3 mx-auto flex-column">
        <Header />
        <h1 class="cover-heading">| - | - | - | - |</h1>
        <p class="lead">in the realm between the known and the unknown </p>
        <p class="lead">
            <a href="/gallery" class="btn btn-lg btn-secondary">light</a>

            <source src="https://d2w1tfvqo2ceap.cloudfront.net/hls/hls.m3u8" type="application/x-mpegURL"></source>
        </p>
        <Footer />
    </main>
);

export default Contact;
