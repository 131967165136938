import Carousel from 'react-bootstrap/Carousel';
import Image from 'react-bootstrap/Image'
import Container from 'react-bootstrap/Container';

function carousel() {
    return (
      <Container>
      <Carousel>
        <Carousel.Item>
            <a href="/graffiti-1">
              <Image  
              src="https://elatednomad-public.s3.us-east-2.amazonaws.com/g/graffiti-1.jpg"
              alt="First slide"
              fluid
              />
            </a>
          <Carousel.Caption>
            <h3>graffiti</h3>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
            <a href="/graffiti-2">
              <Image  
              src="https://elatednomad-public.s3.us-east-2.amazonaws.com/g/graffiti-2.jpg"
              alt="First slide"
              fluid
              />
            </a>
          <Carousel.Caption>
            <h3>graffiti</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
            <a href="/graffiti-3">
              <Image  
              src="https://elatednomad-public.s3.us-east-2.amazonaws.com/g/graffiti-3.jpg"
              alt="First slide"
              fluid
              />
            </a>
          <Carousel.Caption>
            <h3>graffiti</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
            <a href="/graffiti-4">
              <Image  
              src="https://elatednomad-public.s3.us-east-2.amazonaws.com/g/graffiti-4.jpg"
              alt="First slide"
              fluid
              />
            </a>
          <Carousel.Caption>
            <h3>graffiti</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
            <a href="/graffiti-5">
              <Image  
              src="https://elatednomad-public.s3.us-east-2.amazonaws.com/g/graffiti-5.jpg"
              alt="First slide"
              fluid
              />
            </a>
          <Carousel.Caption>
            <h3>graffiti</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
            <a href="/graffiti-6">
              <Image  
              src="https://elatednomad-public.s3.us-east-2.amazonaws.com/g/graffiti-6.jpg"
              alt="First slide"
              fluid
              />
            </a>
          <Carousel.Caption>
            <h3>graffiti</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
            <a href="/graffiti-7">
              <Image  
              src="https://elatednomad-public.s3.us-east-2.amazonaws.com/g/graffiti-9.jpg"
              alt="First slide"
              fluid
              />
            </a>
          <Carousel.Caption>
            <h3>graffiti</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
            <a href="/graffiti-8">
              <Image  
              src="https://elatednomad-public.s3.us-east-2.amazonaws.com/g/graffiti-10.jpg"
              alt="First slide"
              fluid
              />
            </a>
          <Carousel.Caption>
            <h3>graffiti</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
            <a href="/graffiti-9">
              <Image  
              src="https://elatednomad-public.s3.us-east-2.amazonaws.com/g/graffiti-7.jpg"
              alt="First slide"
              fluid
              />
            </a>
          <Carousel.Caption>
            <h3>graffiti</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
            <a href="/graffiti-10">
              <Image  
              src="https://elatednomad-public.s3.us-east-2.amazonaws.com/g/graffiti-8.jpg"
              alt="First slide"
              fluid
              />
            </a>
          <Carousel.Caption>
            <h3>graffiti</h3>
          </Carousel.Caption>
        </Carousel.Item>
        
      </Carousel>
      </Container>
    );
  }
  
  export default carousel;