import Header from '../components/header.component';
import Footer from '../components/footer.component';
import Carousel from '../components/gallery/carousel.component';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LeftNav from '../components/leftnav/leftnav.component';
import GraffitiNav from '../components/leftnav/graffiti.component';


const Graffiti = () => (
    <main role="main" className="inner cover text-center d-flex h-100 p-3 mx-auto flex-column">
        <Header />
        <Container>
            <Row>
                <Col xs={2}>
                    <LeftNav></LeftNav>
                    <GraffitiNav></GraffitiNav>
                </Col>
                <Col xs={10}>
                    <Carousel />
                </Col>
            </Row>
        </Container>

        
        <Footer />
    </main>
);

export default Graffiti;
