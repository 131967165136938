import Header from '../components/header.component';
import Footer from '../components/footer.component';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col'; 
import Row from 'react-bootstrap/Row'
import './main-page.css';
import Image from 'react-bootstrap/Image';
import Stack from 'react-bootstrap/Stack';
import Overlay from 'react-bootstrap/Overlay';
import LeftNav from '../components/leftnav/leftnav.component';



const Portfolio = () => (
    <main role="main" className="inner cover text-center d-flex h-100 p-3 mx-auto flex-column">
        <Header />
            <Container>
                <Row>
                    <Col sm={2}>
                        <LeftNav></LeftNav>
                    </Col>
                    <Col sm={10}> 
                        <Row>
                            <h1>email elatednomad @ gmail dot com</h1>                                
                        </Row>
                    </Col>                     
                </Row>
            </Container>
        <Footer />
    </main>
);

export default Portfolio;