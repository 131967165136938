import Header from '../components/header.component';
import Footer from '../components/footer.component';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Home = () => (
    <main role="main" className="inner cover text-center d-flex h-100 p-3 mx-auto flex-column">
        <Header />
        <Container>
            <Row>
                <h1 className="cover-heading">EXPLORING</h1>
            </Row>
            <Row>
                <Col></Col>
                <Col>
                    <a href="gallery" className="btn btn-lg btn-secondary">light</a>
                </Col>
                <Col>
                    <a href="about" className="btn btn-lg btn-secondary">thought</a>
                </Col>
                <Col></Col>
            </Row>
        </Container>
        <Footer />
    </main>
);

export default Home;
