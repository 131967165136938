import Header from '../components/header.component';
import Footer from '../components/footer.component';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col'; 
import Row from 'react-bootstrap/Row'
import './main-page.css';
import Image from 'react-bootstrap/Image';
import Stack from 'react-bootstrap/Stack';
import LeftNav from '../components/leftnav/leftnav.component';
import GraffitiNav from '../components/leftnav/graffiti.component';



const GraffitiThumbs = () => (
    <main role="main" className="inner cover text-center d-flex h-100 p-3 mx-auto flex-column">
        <Header />
            <Container>
                <Row>
                    <Col sm={2}>
                        <LeftNav></LeftNav>
                        <GraffitiNav></GraffitiNav>
                    </Col>
                    <Col sm={10}> 
                        <Row>
                            <Col sm={6}>
                                <div class="margin-bottom">
                                <Stack gap={4}>
                                    <Image src="https://elatednomad-public.s3.us-east-2.amazonaws.com/g/graffiti-8.jpg"/>    
                                    <Image src="https://elatednomad-public.s3.us-east-2.amazonaws.com/g/graffiti-4.jpg"/>
                                    <Image src="https://elatednomad-public.s3.us-east-2.amazonaws.com/g/graffiti-9.jpg"/>
                                    <Image src="https://elatednomad-public.s3.us-east-2.amazonaws.com/g/graffiti-5.jpg"/>    
                                    <Image src="https://elatednomad-public.s3.us-east-2.amazonaws.com/g/graffiti-6.jpg"/>
                                </Stack>
                                </div>
                            </Col>
                            <Col sm={6}>
                                <Stack gap={4}>
                                    <Image src="https://elatednomad-public.s3.us-east-2.amazonaws.com/g/graffiti-3.jpg"/>
                                    <Image src="https://elatednomad-public.s3.us-east-2.amazonaws.com/g/graffiti-1.jpg"/>
                                    <Image src="https://elatednomad-public.s3.us-east-2.amazonaws.com/g/graffiti-7.jpg"/>
                                    <Image src="https://elatednomad-public.s3.us-east-2.amazonaws.com/g/graffiti-2.jpg"/>
                                    <Image src="https://elatednomad-public.s3.us-east-2.amazonaws.com/g/graffiti-10.jpg"/> 
                                </Stack>
                            </Col>
                                
                        </Row>
                    </Col>                     
                </Row>
            </Container>
        <Footer />
    </main>
);

export default GraffitiThumbs;
