import Header from '../components/header.component';
import Footer from '../components/footer.component';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col'; 
import Row from 'react-bootstrap/Row'
import './main-page.css';
import Image from 'react-bootstrap/Image';
import Stack from 'react-bootstrap/Stack';
import LeftNav from '../components/leftnav/leftnav.component';
import DarkNav from '../components/leftnav/dark.components';



const DarkThumbs = () => (
    <main role="main" className="inner cover text-center d-flex h-100 p-3 mx-auto flex-column">
        <Header />
            <Container>
                <Row>
                    <Col sm={2}>
                        <LeftNav></LeftNav>
                        <DarkNav></DarkNav>
                    </Col>
                    <Col sm={10}> 
                        <Row>
                            <Col sm={6}>
                                <div class="margin-bottom">
                                <Stack gap={4}>
                                    <Image src="https://elatednomad-public.s3.us-east-2.amazonaws.com/blackwhite/bw-14.jpg"/>    
                                    <Image src="https://elatednomad-public.s3.us-east-2.amazonaws.com/blackwhite/bw-3.jpg"/>
                                    <Image src="https://elatednomad-public.s3.us-east-2.amazonaws.com/blackwhite/bw-8.jpg"/>
                                    <Image src="https://elatednomad-public.s3.us-east-2.amazonaws.com/blackwhite/bw-15.jpg"/>    
                                    <Image src="https://elatednomad-public.s3.us-east-2.amazonaws.com/blackwhite/bw-17.jpg"/>
                                    <Image src="https://elatednomad-public.s3.us-east-2.amazonaws.com/blackwhite/bw-32.jpg"/>
                                    <Image src="https://elatednomad-public.s3.us-east-2.amazonaws.com/blackwhite/dark-4.jpg"/>
                                    <Image src="https://elatednomad-public.s3.us-east-2.amazonaws.com/blackwhite/dark-3.jpg"/>
                                    <Image src="https://elatednomad-public.s3.us-east-2.amazonaws.com/blackwhite/dark-7.jpg"/>
                                    <Image src="https://elatednomad-public.s3.us-east-2.amazonaws.com/blackwhite/dark-9.jpg"/>
                                </Stack>
                                </div>
                            </Col>
                            <Col sm={6}>
                                <Stack gap={4}>
                                    <Image src="https://elatednomad-public.s3.us-east-2.amazonaws.com/blackwhite/bw-3.jpg"/>
                                    <Image src="https://elatednomad-public.s3.us-east-2.amazonaws.com/blackwhite/bw-8.jpg"/>
                                    <Image src="https://elatednomad-public.s3.us-east-2.amazonaws.com/blackwhite/dark-1.jpg"/>
                                    <Image src="https://elatednomad-public.s3.us-east-2.amazonaws.com/blackwhite/bw-19.jpg"/>
                                    <Image src="https://elatednomad-public.s3.us-east-2.amazonaws.com/blackwhite/bw-28.jpg"/> 
                                    <Image src="https://elatednomad-public.s3.us-east-2.amazonaws.com/blackwhite/dark-2.jpg"/>
                                    <Image src="https://elatednomad-public.s3.us-east-2.amazonaws.com/blackwhite/dark-5.jpg"/>
                                    <Image src="https://elatednomad-public.s3.us-east-2.amazonaws.com/blackwhite/dark-6.jpg"/>
                                    <Image src="https://elatednomad-public.s3.us-east-2.amazonaws.com/blackwhite/dark-8.jpg"/>
                                </Stack>
                            </Col>
                                
                        </Row>
                    </Col>                     
                </Row>
            </Container>
        <Footer />
    </main>
);

export default DarkThumbs;
