import Header from '../components/header.component';
import Footer from '../components/footer.component';

const About = () => (
     <main role="main" className="inner cover text-center d-flex h-100 p-3 mx-auto flex-column">
        <Header />
        <div className="row">
            <h1 className="cover-heading">| - | - | - | - |....</h1>
            <p className="lead">what is there to say that hasn't already been said....</p>
        </div>
        <div className = "row">
            <div>
                <p className="lead">
                    <a href="/gallery" className="btn btn-lg btn-secondary">light</a>
                </p>
            </div>
        </div>
        <Footer />
    </main>
);

export default About;