import { BrowserRouter as Router, Switch, Route } from "react-router-dom"; 
import './main-page.css';
import About from './about';
import Gallery from './gallery';
import Contact from './contact';
import Home from './home';
import Portfolio from './portfolio';
import Prints from './prints';
import Thumbs from './thumbs';
import Graffiti from "./graffiti";
import GraffitiThumbs from "./graffiti_thumbs";
import Dark from "./dark";
import DarkThumbs from "./dark_thumbs"

function App() {

  return (  
    <Router> 
        <Switch>

          <Route path="/about">
            <About></About>
          </Route>
          <Route path="/contact">
            <Contact></Contact>
          </Route>
          <Route path="/gallery">
            <Gallery></Gallery>
          </Route>
          <Route path="/portfolio">
            <Portfolio></Portfolio>
          </Route>
          <Route path="/prints">
            <Prints></Prints>
          </Route>
          <Route path="/thumbs">
            <Thumbs></Thumbs>
          </Route>
          <Route path="/graffiti">
            <Graffiti></Graffiti>
          </Route>
          <Route path="/graffiti_thumbs">
            <GraffitiThumbs></GraffitiThumbs>
          </Route>
          <Route path="/dark">
            <Dark></Dark>
          </Route>
          <Route path="/dark_thumbs">
            <DarkThumbs></DarkThumbs>
          </Route>
          <Route path="/">
            <Home></Home>
          </Route>
        </Switch> 
    </Router>
  );
}

export default App;

