import Carousel from 'react-bootstrap/Carousel';
import Image from 'react-bootstrap/Image'
import Container from 'react-bootstrap/Container';

function dark_carousel() {
    return (
      <Container>
      <Carousel>
        <Carousel.Item>
            <a href="/dark-1">
              <Image  
              src="https://elatednomad-public.s3.us-east-2.amazonaws.com/blackwhite/bw-3.jpg"
              alt="First slide"
              fluid
              />
            </a>
          <Carousel.Caption>
            <h3>dark</h3>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
            <a href="/dark-3">
              <Image  
              src="https://elatednomad-public.s3.us-east-2.amazonaws.com/blackwhite/bw-8.jpg"
              alt="First slide"
              fluid
              />
            </a>
          <Carousel.Caption>
            <h3>dark</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
            <a href="/dark-4">
              <Image  
              src="https://elatednomad-public.s3.us-east-2.amazonaws.com/blackwhite/bw-14.jpg"
              alt="First slide"
              fluid
              />
            </a>
          <Carousel.Caption>
            <h3>dark</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
            <a href="/dark-5">
              <Image  
              src="https://elatednomad-public.s3.us-east-2.amazonaws.com/blackwhite/bw-15.jpg"
              alt="First slide"
              fluid
              />
            </a>
          <Carousel.Caption>
            <h3>dark</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
            <a href="/dark-7">
              <Image  
              src="https://elatednomad-public.s3.us-east-2.amazonaws.com/blackwhite/bw-17.jpg"
              alt="First slide"
              fluid
              />
            </a>
          <Carousel.Caption>
            <h3>dark</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
            <a href="/dark-8">
              <Image  
              src="https://elatednomad-public.s3.us-east-2.amazonaws.com/blackwhite/bw-19.jpg"
              alt="First slide"
              fluid
              />
            </a>
          <Carousel.Caption>
            <h3>dark</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
            <a href="/dark-9">
              <Image  
              src="https://elatednomad-public.s3.us-east-2.amazonaws.com/blackwhite/bw-28.jpg"
              alt="First slide"
              fluid
              />
            </a>
          <Carousel.Caption>
            <h3>dark</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
            <a href="/dark-10">
              <Image  
              src="https://elatednomad-public.s3.us-east-2.amazonaws.com/blackwhite/bw-32.jpg"
              alt="First slide"
              fluid
              />
            </a>
          <Carousel.Caption>
            <h3>dark</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
            <a href="/dark-10">
              <Image  
              src="https://elatednomad-public.s3.us-east-2.amazonaws.com/blackwhite/dark-1.jpg"
              alt="First slide"
              fluid
              />
            </a>
          <Carousel.Caption>
            <h3>dark</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
            <a href="/dark-10">
              <Image  
              src="https://elatednomad-public.s3.us-east-2.amazonaws.com/blackwhite/dark-2.jpg"
              alt="First slide"
              fluid
              />
            </a>
          <Carousel.Caption>
            <h3>dark</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
            <a href="/dark-10">
              <Image  
              src="https://elatednomad-public.s3.us-east-2.amazonaws.com/blackwhite/dark-3.jpg"
              alt="First slide"
              fluid
              />
            </a>
          <Carousel.Caption>
            <h3>dark</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
            <a href="/dark-10">
              <Image  
              src="https://elatednomad-public.s3.us-east-2.amazonaws.com/blackwhite/dark-4.jpg"
              alt="First slide"
              fluid
              />
            </a>
          <Carousel.Caption>
            <h3>dark</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
            <a href="/dark-10">
              <Image  
              src="https://elatednomad-public.s3.us-east-2.amazonaws.com/blackwhite/dark-5.jpg"
              alt="First slide"
              fluid
              />
            </a>
          <Carousel.Caption>
            <h3>dark</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
            <a href="/dark-10">
              <Image  
              src="https://elatednomad-public.s3.us-east-2.amazonaws.com/blackwhite/dark-6.jpg"
              alt="First slide"
              fluid
              />
            </a>
          <Carousel.Caption>
            <h3>dark</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
            <a href="/dark-10">
              <Image  
              src="https://elatednomad-public.s3.us-east-2.amazonaws.com/blackwhite/dark-7.jpg"
              alt="First slide"
              fluid
              />
            </a>
          <Carousel.Caption>
            <h3>dark</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
            <a href="/dark-10">
              <Image  
              src="https://elatednomad-public.s3.us-east-2.amazonaws.com/blackwhite/dark-8.jpg"
              alt="First slide"
              fluid
              />
            </a>
          <Carousel.Caption>
            <h3>dark</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
            <a href="/dark-10">
              <Image  
              src="https://elatednomad-public.s3.us-east-2.amazonaws.com/blackwhite/dark-9.jpg"
              alt="First slide"
              fluid
              />
            </a>
          <Carousel.Caption>
            <h3>dark</h3>
          </Carousel.Caption>
        </Carousel.Item>
        </Carousel>
      </Container>
    );
  }
  
  export default dark_carousel;