import Row from 'react-bootstrap/Row';
import Stack from 'react-bootstrap/Stack';

const GraffitiNav = () => (
    <div>
        <Row>
            <Stack gap={4}>
                <p></p>
                <p className="ms-auto text"><a href="/graffiti" className="nav-link">carousel</a></p>
                <p className="ms-auto text"><a href="/graffiti_thumbs" className="nav-link">thumbs</a></p>
                <p className="ms-auto text"><a href="/prints" className="nav-link">prints</a></p>
            </Stack>
        </Row>
    </div>
);

export default GraffitiNav;