import Header from '../components/header.component';
import Footer from '../components/footer.component';

const Thumbs = () => (
    <main role="main" className="inner cover text-center d-flex h-100 p-3 mx-auto flex-column">
        <Header />
        <Footer />
    </main>
);

export default Thumbs;