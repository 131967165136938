import Row from 'react-bootstrap/Row';
import Stack from 'react-bootstrap/Stack';


const LeftNav = () => (
    <div>
        <Row>
            <Stack gap={4}>
                    <p className="ms-auto text"><a href="/graffiti" className="nav-link">graffiti</a></p>
                    <p className="ms-auto text"><a href="/dark" className="nav-link">dark</a></p>
                    <p className="ms-auto text"><a href="/" className="nav-link"></a></p>
            </Stack>
        </Row>
        <Row>
            <line />
        </Row>
    </div>
);

export default LeftNav;




